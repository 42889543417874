<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary"/>
    <div class="wrapper">
      <div id="content">
    <v-container :style="{ backgroundColor: setBackgroundColor() }">
      <v-row align="center" justify="center">
        <v-col class="font-weight-bold" :style="{ fontFamily: 'Cherolina', fontSize: '80px' }" cols="12">
          Galerie
        </v-col>
      </v-row>
      <v-row>
        <v-tabs
            color="primary"
            centered
            tabindex="page"
            v-on:change="tabsChanged"
            background-color="transparent"
        >
          <v-tab class="transparent" v-for="(typ, index) in types" v-bind:key="index" v-bind:value="typ.name" >{{ typ.name }}</v-tab>
          </v-tabs>

          <v-window class="transparent" align="center" style="margin: auto">
            <v-window-item v-for="n in types.length" v-bind:key="n">
              <v-container align="center" fluid>
                <v-row align="center" justify="center">
                  <v-col
                      v-for="j in elementsPerPage"
                      class="d-flex child-flex"
                      :key="((j-1) * elementsPerPage) + (page-1)"
                      xl="4"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                      cols="12"
                  >
                    <div v-on:click="clickOnImage((j-1) + ((page-1) * elementsPerPage))">
                      <v-card>
                        <v-img
                            v-if="images[(j-1) + ((page-1) * elementsPerPage)] !== undefined"
                            :src="images[(j-1) + ((page-1) * elementsPerPage)].iPath"
                            :lazy-src="require('../../assets/nicetoknow/comingsoon.jpg')"
                            alt="Error"
                            aspect-ratio="1">
                          <v-card-title>
                            <v-spacer></v-spacer>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-pagination
                  v-model="page"
                  :length="calculatePages()"
                  :next="nextPage()"
                  :previous="previousPage()"
                  :prev-icon="icons.mdiChevronLeft"
                  :next-icon="icons.mdiChevronRight"
              >
              </v-pagination>
            </v-window-item>
          </v-window>
      </v-row>
      <v-overlay opacity="0.8" :value="overlay">
        <div>
          <v-container fluid>
            <v-row>
              <v-card flat class="rounded-0">
                <div>
                  <v-img
                      v-if="images[clickedImageIndex] !== undefined"
                      contain
                      max-height="80vh"
                      max-width="80vw"
                      alt="Error"
                      :src="images[clickedImageIndex].iPath"
                      :lazy-src="require('../../assets/nicetoknow/comingsoon.jpg')">
                    <v-card-title>
                      <v-spacer></v-spacer>
                      <v-btn v-on:click="closeOverlay()" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
                    </v-card-title>
                  </v-img>
                </div>
              </v-card>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="1">
                <v-btn icon v-on:click="previousPicture()"><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn>
              </v-col>
              <v-col cols="2">
                {{ clickedImageIndex+1 }} von {{ images.length }}
              </v-col>
              <v-col cols="1">
                <v-btn icon v-on:click="nextPicture()"><v-icon>{{ icons.mdiChevronRight }}</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-overlay>
    </v-container>
        </div>
  </div>
  </div>
</template>




<script>
import { mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js'
import Header from "../Header/Header";
import axios from "axios";
import sharedfunctions from "../../sharedfunctions";
export default {
  name: "Galerie",
  components: {
    Header,
  },
  data: () => ({
    foreground: {
      maxWidth: "720px" /* just for example */,
      margin: "0 auto",
      float: "auto",
      width: "500",
      height: "auto",
      /*backdropFilter: "blur(5px)"*/
    },
    icons:
        {
          mdiChevronLeft,
          mdiChevronRight,
          mdiClose
        },
    types: [
      { name: "Shooting", key: "0"},
      { name: "Standesamt", key: "1"},
      { name: "Apéro", key: "2"}
    ],
    overlay: false,
    deleteOverlay: false,
    page: 1,
    pages: 0,
    images: [],
    elementsPerPage: 9,
    currentTab: 0,
    clickedImageIndex: null,
    clickedImageId: null,
  }),
  methods: {
    imageDeleted() {
      this.deleteOverlay = false;
      this.loadImages();
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/backgrounds/background.jpg");
        case 'sm':
          return require("../../assets/backgrounds/background.jpg");
        case 'md':
          return require("../../assets/backgrounds/background_q.jpg");
        case 'lg':
          return require("../../assets/backgrounds/background_q.jpg");
        case 'xl':
          return require("../../assets/backgrounds/background_q.jpg");
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteImage(index) {
      event.stopImmediatePropagation();
      this.clickedImageId = this.images[index].iID;
      this.deleteOverlay = !this.deleteOverlay;
    },
    closeOverlay() {
      let pageToOpen = Math.ceil(((this.clickedImageIndex + 1) / this.elementsPerPage));
      this.page = pageToOpen;
      this.overlay = false;
    },
    openDeleteOverlay() {
    },
    closeDeleteOverlay() {
      this.deleteOverlay = false;
      this.loadImages();
    },
    loadImages() {
      axios
          .get("https://sabrinaundsilas-production-d248.up.railway.app/api/image/getImages/" + ((this.currentTab)+1))
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            this.images = JSON.parse(JSON.stringify(res.data));
            this.errorMessage = "Bei der Verarbeitung der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!"
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            this.errorMessage = "Bei der Verarbeitung der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!"
            console.log(error);
            // error.response.status Check status code
          })
          .finally(() => {
            //Perform action in always
          });
    },
    // eslint-disable-next-line no-unused-vars
    tabsChanged(event) {
      this.currentTab = event;
      this.loadImages();
      this.page = 1;
    },
    setBackgroundColor() {
      if (this.isMobile()) {
        return 'rgba(241,243,240,0.4)'
      } else {
        return 'rgba(241,243,240,0.8)'
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    clickOnImage(index) {
      this.clickedImageIndex = index;
      this.clickedImageId = this.images[this.clickedImageIndex].iID;
      this.overlay = !this.overlay;
    },
    calculatePages() {
      if (this.images.length == 0) {
        this.pages = 1;
      } else {
        this.pages = Math.ceil(this.images.length / this.elementsPerPage);
      }
      return this.pages;
    },
    previousPicture() {
      if (this.clickedImageIndex == 0) {
        return;
      } else {
        this.clickedImageIndex--;
        this.clickedImageId = this.images[this.clickedImageIndex];
      }
    },
    nextPicture() {
      if (this.clickedImageIndex == (this.images.length-1)) {
        return;
      } else {
        this.clickedImageIndex++;
        this.clickedImageId = this.images[this.clickedImageIndex];
      }
    },
    nextPage() {
      this.scrollToTop();
    },
    previousPage() {
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    initColorScheme() {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile()) {
        this.updateDarkMode(false);
      } else {
        this.updateDarkMode(false);
      }
    }
  },
  beforeMount(){
    this.$vuetify.theme.dark = false;
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (!this.overlay) {
        return;
      }
      if (e.key == "ArrowRight") {
        this.nextPicture();
      } else if (e.key == "ArrowLeft") {
        this.previousPicture();
      } else if (e.key == "Esc" || e.key == "Escape") {
        this.closeOverlay();
      }
    });
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    isLoggedIn : function() {
      return this.$store.getters.isAuthenticated;
    }
  },
}
</script>



<style scoped>
#tab {
 background-color: transparent;
}
.card {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>